exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-extra-life-tsx": () => import("./../../../src/pages/extra-life.tsx" /* webpackChunkName: "component---src-pages-extra-life-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-bst-20-index-tsx": () => import("./../../../src/pages/projects/bst20/index.tsx" /* webpackChunkName: "component---src-pages-projects-bst-20-index-tsx" */),
  "component---src-pages-projects-bst-20-video-id-tsx": () => import("./../../../src/pages/projects/bst20/[videoID].tsx" /* webpackChunkName: "component---src-pages-projects-bst-20-video-id-tsx" */),
  "component---src-pages-projects-emulated-index-tsx": () => import("./../../../src/pages/projects/emulated/index.tsx" /* webpackChunkName: "component---src-pages-projects-emulated-index-tsx" */),
  "component---src-pages-projects-emulated-video-id-tsx": () => import("./../../../src/pages/projects/emulated/[videoID].tsx" /* webpackChunkName: "component---src-pages-projects-emulated-video-id-tsx" */),
  "component---src-pages-projects-swe-1-r-hd-4-k-index-tsx": () => import("./../../../src/pages/projects/swe1r-hd4k/index.tsx" /* webpackChunkName: "component---src-pages-projects-swe-1-r-hd-4-k-index-tsx" */),
  "component---src-pages-projects-swe-1-r-hd-4-k-video-id-tsx": () => import("./../../../src/pages/projects/swe1r-hd4k/[videoID].tsx" /* webpackChunkName: "component---src-pages-projects-swe-1-r-hd-4-k-video-id-tsx" */),
  "component---src-pages-projects-zelda-albw-hd-4-k-index-tsx": () => import("./../../../src/pages/projects/zelda-albw-hd4k/index.tsx" /* webpackChunkName: "component---src-pages-projects-zelda-albw-hd-4-k-index-tsx" */),
  "component---src-pages-projects-zelda-albw-hd-4-k-video-id-tsx": () => import("./../../../src/pages/projects/zelda-albw-hd4k/[videoID].tsx" /* webpackChunkName: "component---src-pages-projects-zelda-albw-hd-4-k-video-id-tsx" */),
  "component---src-pages-projects-zelda-modders-toolkit-index-tsx": () => import("./../../../src/pages/projects/zelda-modders-toolkit/index.tsx" /* webpackChunkName: "component---src-pages-projects-zelda-modders-toolkit-index-tsx" */),
  "component---src-pages-projects-zelda-modders-toolkit-video-id-tsx": () => import("./../../../src/pages/projects/zelda-modders-toolkit/[videoID].tsx" /* webpackChunkName: "component---src-pages-projects-zelda-modders-toolkit-video-id-tsx" */),
  "component---src-pages-twitch-stream-index-tsx": () => import("./../../../src/pages/twitch-stream/index.tsx" /* webpackChunkName: "component---src-pages-twitch-stream-index-tsx" */),
  "component---src-pages-twitch-stream-video-id-tsx": () => import("./../../../src/pages/twitch-stream/[videoID].tsx" /* webpackChunkName: "component---src-pages-twitch-stream-video-id-tsx" */),
  "component---src-pages-video-id-tsx": () => import("./../../../src/pages/[videoID].tsx" /* webpackChunkName: "component---src-pages-video-id-tsx" */),
  "component---src-pages-youtube-videos-index-tsx": () => import("./../../../src/pages/youtube-videos/index.tsx" /* webpackChunkName: "component---src-pages-youtube-videos-index-tsx" */),
  "component---src-pages-youtube-videos-video-id-tsx": () => import("./../../../src/pages/youtube-videos/[videoID].tsx" /* webpackChunkName: "component---src-pages-youtube-videos-video-id-tsx" */)
}

